<template>
	<b-card v-if="charts" no-body class="card-statistics">
		<b-card-body class="statistics-body" style="padding: 0 !important">
			<div class="mb-2 mt-50">
				<h3>{{ data.project_id }}</h3>
				<h6>{{ data.project.SP_TITLE }}</h6>
			</div>

			<b-media no-body>
				<b-media-body class="mb-1 border-bottom d-flex justify-content-between">
					<h4 class="font-weight-bold font-small-4 mb-0">
						Sector
					</h4>
					<b-card-text class="font-small-3 font-weight-bolder mb-0">
						{{ data.project.sector }}
					</b-card-text>
				</b-media-body>
			</b-media>
			<b-media no-body>
				<b-media-body class="mb-1 border-bottom d-flex justify-content-between">
					<h4 class="font-weight-bold font-small-4 mb-0">
						Subsector
					</h4>
					<b-card-text class="font-small-3 font-weight-bolder mb-0">
						{{ data.project.Sub_S_Desc_A }}
					</b-card-text>
				</b-media-body>
			</b-media>

			<b-media no-body>
				<b-media-body class="mb-1 border-bottom d-flex justify-content-between">
					<h4 class="font-weight-bold font-small-4 mb-0">
						Doner
					</h4>
					<b-card-text class="font-small-3 font-weight-bolder mb-0">
						{{ data.project.SP_DONER }}
					</b-card-text>
				</b-media-body>
			</b-media>

			<b-button size="sm" class="mt-1" :to="{ name: 'project.details', params: { id: data.project_id } }">
				View more
			</b-button>
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardText, BCardBody, BMedia, BMediaBody, BButton } from 'bootstrap-vue';

export default {
	components: {
		BCard,
		BCardText,
		BCardBody,
		BMedia,
		BMediaBody,
		BButton,
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		amount: Math.ceil(Math.random() * 256452),
		charts: [
			{
				icon: 'UsersIcon',
				color: 'light-primary',
				title: Math.ceil(Math.random() * 2545),
				subtitle: 'Population',
				customClass: 'mb-2 mb-xl-0',
			},
			{
				icon: 'DollarSignIcon',
				color: 'light-info',
				title: Math.ceil(Math.random() * 4545),
				subtitle: 'Economic',
				customClass: 'mb-2 mb-xl-0',
			},
			{
				icon: 'PackageIcon',
				color: 'light-danger',
				title: Math.ceil(Math.random() * 52552),
				subtitle: 'Services',
				customClass: 'mb-2 mb-sm-0',
			},
			{ icon: 'LayersIcon', color: 'light-success', title: Math.ceil(Math.random() * 425), subtitle: 'NGOs', customClass: '' },
		],
		trans: [
			{ mode: 'Education', avatar: 'PenToolIcon', avatarVariant: 'light-primary' },
			{
				mode: 'Health',
				avatar: 'PocketIcon',
				avatarVariant: 'light-success',
			},
			{
				mode: 'Sanitation',
				avatar: 'RepeatIcon',
				avatarVariant: 'light-danger',
			},
			{
				mode: 'Roads',
				avatar: 'TruckIcon',
				avatarVariant: 'light-warning',
			},
		],
		economics: [
			{ mode: 'Internal migration', payment: Math.ceil(Math.random() * 4205) },
			{
				mode: 'Foreign migration',
				payment: Math.ceil(Math.random() * 4205),
			},
			{
				mode: 'Fisheries',
				payment: Math.ceil(Math.random() * 4205),
			},
			{
				mode: 'Animal husbandry',
				payment: Math.ceil(Math.random() * 4205),
			},
			{
				mode: 'Agriculture',
				payment: Math.ceil(Math.random() * 4205),
			},
			{
				mode: 'Trade',
				payment: Math.ceil(Math.random() * 4205),
			},
		],
	}),
};
</script>

<style scoped>
.hide {
	height: 0;
	visibility: hidden;
}

.price {
	color: #646464;
	margin-bottom: 1rem;
}
</style>
