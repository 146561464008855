<template>
	<b-button variant="primary" :disabled="loading" v-bind="$attrs" @click="exportExcel" v-on="$listeners">
		<b-spinner v-show="loading" class="align-middle" style="width: 1rem; height: 1rem;"></b-spinner>
		<span v-show="!loading"><slot>Export Excel</slot></span>
	</b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';
import api from '@/utils/api';

export default {
	name: 'LoadingDownloadBtn',

	components: { BButton, BSpinner },

	props: {
		url: { type: String, require: true },
		fileName: { type: String, default: 'excel-file' },
	},

	data: () => ({
		loading: false,
	}),

	methods: {
		exportExcel() {
			this.loading = true;

			api.getFile(this.url)
				.then((response) => {
					const url = URL.createObjectURL(
						new Blob([response.data], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						}),
					);

					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', this.fileName);
					document.body.appendChild(link);
					link.click();
				})
				.catch((error) => console.log(error))
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped></style>
