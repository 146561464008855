<template>
	<b-card>
		<b-card-header>
			<h2>Project Locations</h2>
			<excel-download-btn url="/projects/locations/export" file-name="project-location" />
		</b-card-header>

		<l-map :zoom="zoom" :center="center">
			<l-tile-layer :url="url" />
			<v-marker-group v-for="group in groups" :key="group">
				<l-marker v-for="(loc, i) in data[group]" :key="i" :lat-lng="[loc.latitude, loc.longitude]">
					<l-popup>
						<popup :data="loc" />
					</l-popup>
				</l-marker>
			</v-marker-group>
		</l-map>
	</b-card>
</template>

<script>
import store from '@/store';
import { BCard, BCardHeader } from 'bootstrap-vue';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import 'leaflet/dist/leaflet.css';
import ExcelDownloadBtn from '@/components/ExcelDownloadBtn.vue';
import Popup from './popup.vue';

export default {
	name: 'PWPMap',

	components: {
		LMap,
		BCardHeader,
		LTileLayer,
		LMarker,
		LPopup,
		BCard,
		'v-marker-group': Vue2LeafletMarkerCluster,
		Popup,
		ExcelDownloadBtn,
	},

	data() {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			zoom: 7,
			center: [15.337524621435966, 44.179933859995614],
			data: null,
		};
	},

	computed: {
		groups() {
			return this.data ? Object.keys(this.data) : [];
		},
	},

	async created() {
		this.data = await store.dispatch('reports/maps/getLocations');
	},
};
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.vue2leaflet-map {
	&.leaflet-container {
		height: 450px;
	}
}

.leaflet-popup-content p {
	margin: 0;
}
</style>
